






















































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import DialogoDeEdicaoDeEstacao from '@/components/produto/DialogoDeEdicaoDeEstacao.vue'
import { Estacao, Page } from '@/models'
import { CancelToken } from 'axios'
import { Pageable } from '@/models/Pageable'
import { FindEstacaoUseCase } from '@/usecases'

@Component({
	components: {
		TelaGenerica,
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeEstacao,
	},
})

export default class TelaDeEstacoes extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeEstacao

	findEstacaoUseCase = new FindEstacaoUseCase()
	estacoes: Estacao[] = []
	busca = ''
	carregando = false
	editando = false

	headers = [
		{ text: 'Estacao', value: 'nome' },
	]

	abrirDialogoDeEditarEstacao(indice: number) {
		this.editando = true
		const estacao = this.estacoes[indice]
		this.dialogoDeEdicao.mostrar(estacao)
	}

	abrirDialogoDeCriarEstacao() {
		this.editando = false
		const estacao = {id:null,nome:""}
		this.dialogoDeEdicao.mostrar(estacao)
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Estacao>> {
		const pagina = await this.findEstacaoUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.estacoes = pagina.content
		return pagina
	}

	estacaoSalva(estacaoSalva) {
		const indice = this.estacoes.findIndex(marca => marca.id === estacaoSalva.id)

		if (indice === -1) {
			this.estacoes.push(estacaoSalva)
		} else {
			this.estacoes.splice(indice, 1, estacaoSalva)
		}
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
